module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Saint Neo","short_name":"Saint Neo","start_url":"/en/","background_color":"#ffffff","theme_color":"#222222","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8b512faa8d4a0b019c123a771b6622aa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.raelabs.net","noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"generateMatchPathRewrites":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ar","az","bg","bn","ca","cs","da","de","el","es","fa","fi","fr","gl","gu","he","hi","hr","hu","id","ig","it","ja","ka","kk","km","ko","lt","ml","mr","ms","nl","nb","pcm","fil","pl","pt","pt-br","ro","ru","se","sk","sl","sr","sw","ta","th","tr","uk","ur","uz","vi","zh","zh-tw"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://www.raelabs.net","trailingSlash":"always","i18nextOptions":{"fallbackLng":"en","interpolation":{"escapeValue":false},"load":"currentOnly","lowerCaseLng":true,"cleanCode":true,"react":{"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i","bold","b","em","sup"]},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
